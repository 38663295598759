.page_contact #contenu{
  margin-top: 3rem;
  margin-bottom: 3rem;
  .h3{
    margin-bottom: 1.5rem;
  }
  .required-label{
    font-size: 1.2rem;
    margin-top: 1rem;
    .icon{
      font-size: 3rem;
      line-height: 1;
    }
  }
  button[type='submit']{
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
  .day{
    font-weight: 700;
  }
  .bloc_contact{
    margin-bottom: 2rem;
  }
  #map{
    min-height: 50rem;
  }
}
