/* Slider avec slide affichée 1 par 1 */
.singleslide,
.singleslide .slide img {
  height: 50rem;
}

.singleslide{
  padding-left: 0;
  margin-bottom: 0;
  .slide{
    position: relative;
    img{
      width: 100%;
      object-fit: cover;
    }
    .islide_content{
      @include absolute_container;
      @include flex_center;
      .islide{
        width: 45%;
        padding: 4rem;
        text-align: center;
        background-color: rgba(255, 255, 255, .7);
        .h1{
          margin-bottom: 1.5rem;
        }
        .h3{
          margin-bottom: 3rem;
        }
        .button{
          cursor: pointer;
          &:hover{
            color: $color1;
            background-color: #fff;
          }
        }
      }
    }
  }
  .slick-prev {
    left: 3.5rem;
  }
  .slick-next {
    right: 3.5rem;
  }
  .slick-next::before,
  .slick-prev::before {
    color: $color2;
  }
  .slick-dots{
    bottom: 1.5rem;
    li button::before{
      background-color: $color2;
    }
  }
}

/* Slider avec plusieurs slides affichées */
.multi_slider{
  margin-left: -1rem;
  margin-right: -1rem;
  .slick-slide{
    position: relative;
    background-color: #eee;
    margin-left: 1rem;
    margin-right: 1rem;
    .islide_content{
      padding: .5rem 1rem 1rem;
      .desc{
        height: 7rem;
        overflow: hidden;
      }
    }
  }
  .slick-prev {
    left: -4rem;
  }
  .slick-next {
    right: -4rem;
  }
}

/* Slider avec des vignettes (page news) */

.slider-single{
  .image_produit_container{
    position: relative;
    padding-bottom: 75.6%; // A ajuster en fonction du ratio de l'image
    .image_produit_content,
    figure {
      @include absolute_container;
      @include box_shadow;
      @include flex_center;
      cursor: pointer;
    }
  }
}

.slider-nav{
  .slick-track{
    margin: 0 1rem 1rem;
    .slick-slide{
      margin-right: 1.5rem;
      img{
        object-fit: cover;
        @include box_shadow_light;
        cursor: pointer;
        height: 7.5rem;
        border: 2px solid transparent;
        padding: .3rem;
      }
    }
  }
  .slick-slide.is-active img{
    border-color: $color1;
  }
}

.fancybox-thumbs__list a::before {
  border: 4px solid $color1;
}

.tag_actu{
  display: inline-block;
  color: #fff;
  background-color: $color1;
  padding: .1rem .3rem;
  margin-bottom: .4rem;
}

@media (max-width: $breakpoint-xs) {
  .singleslide,
  .singleslide .slide img{
    height: 35rem;
  }
  .singleslide .slide .islide_content .islide{
    width: 90%;
    padding: 1rem;
  }
}
