/* Slider */
.slick-slider{
  padding-left: 0;
}
.slick-loading .slick-list
{
  background: none;
}
/* Icons */
@font-face
{
  font-family: 'slick';
}

.slick-prev:before,
.slick-next:before
{
  font-family: inherit;
}

.slick-dots{
  bottom: 3.5rem;
  right: 6rem;
  width: auto;
  li{
    button{
      background-color: #D4CECB;
      transition: background-color .3s;
      width: 1.6rem;
      height: 1.6rem;
      &::before {
        border-radius: 0;
        display: none;
        content: none;
      }
      &:hover{
        background-color: $color4;
      }
    }
    &.slick-active button{
      background-color: $color1;
      &::before{
        content: none
      }
    }
  }
}

.slick-prev,
.slick-next{
  width: 5rem;
  height: 5rem;
}

.slick-prev{
  left: -5rem;
}

.slick-next {
  right: -5rem;
}

.slick-prev::before,
.slick-next::before {
  font-family: 'kfont';
  color: #CBCBCB;
  font-size: 5rem;
}

@media (max-width: $breakpoint-lg) {
  .slick-prev{
    left: -4rem;
  }
  .slick-next{
    right: -4rem;
  }
}

@media (max-width: $breakpoint-md) {
  .apropos_home .made_fr {
    font-size: 2.4rem;
  }
  .slick-dots{
    right: 2rem;
    bottom: 1.5rem;
  }
  .slider_competences{
    margin-top: 3rem;
    .slick-dots{
      position: static;
    }
  }
}