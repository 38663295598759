#footer{
  color: #fff;
  background-color: $color3;
  margin-bottom: 3rem;
  padding-top: 6rem;
  padding-bottom: 4rem;
  a{
    color: #fff;
    &:hover,
    &:focus{
      color: $color1;
    }
  }
  .footer_c{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo_footer{
      max-width: 28rem;
      margin-right: 2rem;
      img{
        width: 14.5rem;
      }
      p{
        margin-top: 2.8rem;
        font-size: 1.2rem;
        color: #fff;
      }
    }
    .infos_footer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 90rem;
      .tel_footer{
        font-size: 2.4rem;
        font-weight: 600;
        .icon{
          margin-right: 1.5rem;
        }
      }
    }
    .address_header{
      display: flex;
      align-items: center;
      .icon{
        font-size: 3rem;
        margin-right: 1.5rem;
      }
    }
    .reseaux_footer{
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      a{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        color: $color1;
        &:hover,
        &:focus{
          color: $color7;
        }
      }
    }
    #menu_footer ul{
      list-style-type: none;
      margin-bottom: 0;
      padding-left: 0;
      font-size: 1.4rem;
    }
  }

  .footer_bot{
    text-align: right;
    color: $color5;
    a{
      font-size: 1.4rem;
      color: $color5;
      &:hover,
      &:focus{
        color: $color7;
      }
    }
  }
}

.sep_verti_color1{
  display: block;
  height: 6.6rem;
  width: .1rem;
  background-color: $color1;
}

.sep_hori_color1{
  display: block;
  height: .1rem;
  width: 80%;
  background-color: $color1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}

/* Signature Kyxar */
.kyxar {
  position: absolute;
  z-index: 9;
  white-space: nowrap;
  right: 1rem;
  margin-top: -3rem;
  padding: 0;
  line-height: 2.5rem;
}

.kyxar a {
  color: $color10;
  font-size: 1rem;
  text-decoration: none;
  letter-spacing: 1px;
  position: relative
}

.kyxar a:hover {
  color: $color10;
}

.kyxar a .none {
  position: absolute;
  bottom: 3.8rem;
  right: 2.8rem;
  height: 0;
  overflow: hidden;
  color: $color3;
}

.kyxar a:hover .none {
  overflow: visible
}

.kyxar a .none span {
  display: inline-block;
  background-color: #fff;
  border-radius: .3rem;
  padding: .5rem 1rem;
  line-height: 1;
  font-weight: 300;
  font-size: 1rem
}

@media (max-width: $breakpoint-xl) {
  #footer .footer_c{
    .logo_footer{
      max-width: 23rem;
    }
    .infos_footer{
      max-width: 72rem;
      .tel_footer{
        font-size: 1.8rem;
      }
      .address_header{
        font-size: 1.6rem;
      }
    }
    .reseaux_footer a{
      margin-left: .5rem;
      margin-right: .5rem;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  #footer{
    padding-top: 2rem;
    padding-bottom: 2rem;
    .footer_c{
      flex-wrap: wrap;
      .logo_footer{
        max-width: 20rem;
      }
      .infos_footer{
        max-width: none;
        width: calc(100% - 22rem);
        margin-top: 1rem;
        margin-bottom: 2rem;
      }
      .reseaux_footer{
        width: 100%;
        text-align: right;
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  #footer .footer_c{
    .logo_footer{
      max-width: 30rem;
    }
    .reseaux_footer{
      width: 15rem;
      text-align: center;
    }
    .infos_footer{
      width: 100%;
      order: 2;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  #footer{
    .footer_c{
      .infos_footer{
        flex-wrap: wrap;
        > div{
          width: 100%;
          text-align: center;
          &.sep_verti_color1{
            height: .1rem;
            max-width: 20rem;
            margin: 1.5rem auto;
          }
          .address_header{
            justify-content: center;
          }
        }
      }
      .logo_footer{
        max-width: none;
        margin-right: 0;
      }
      .reseaux_footer{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
    .footer_bot{
      text-align: center;
    }
  }
}