.ref-filtre ul li{
  display: inline-block;
  margin-right: .5rem;
  margin-bottom: .8rem;
  a{
    display: inline-block;
    background-color: $color1;
    color: #ffffff;
    padding: .8rem 1.5rem;
    border-radius: .2rem;
    font-size: 1.4rem;
    line-height: 1rem;
    &:hover{
      background-color: $color2;
    }
  }
}

#realisations ul{
  margin: 0;
  padding: 0;
  list-style: none;
  background : $color8;
  .real_item {
    position : relative;
    width : calc(100% / 4);
    height : 0;
    margin: 0;
    padding: calc(100% / 7) 0 0;
    overflow : hidden;
  }
  li{
    img{
      display : block;
      width : 100%;
      min-width : 100%;
      z-index : 9;
    }
    .overlay {
      display : block;
      position : absolute;
      top : 0;
      left : 0;
      width : 100%;
      height : 100%;
      padding : 3rem;
      transition : background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
      background-color : rgba(241, 120, 75, 0);
      z-index : 10;
      .title{
        font-size : 2rem;
        color : #fff;
        font-weight : 700;
        text-align : center;
        margin-bottom: auto;
      }
      .description {
        color : #fff;
        font-size : 1.8rem;
      }
    }
    &:hover .overlay{
      opacity : 1;
      visibility : visible;
    }
  }
}

/*---------------*/
/***** Bubba *****/
/*---------------*/

.effect-bubba {
  background : $color6;
  img {
    opacity: 0.9;
    transform: scale(1.12);
    transition: opacity 0.35s, transform 0.35s, filter 0.35s;
  }
  .overlay{
    &::before,
    &::after{
      content: '';
      position: absolute;
      top: 1.5rem;
      right: 2rem;
      bottom: 2rem;
      left: 1.5rem;
      opacity: 0;
      transition: opacity 0.35s, transform 0.35s;
    }
    &::before{
      border-top: .1rem solid #fff;
      border-bottom: .1rem solid #fff;
      transform: scale(0,1);
    }
    &::after{
      border-right: .1rem solid #fff;
      border-left: .1rem solid #fff;
      transform: scale(1,0);
    }
  }
  .title {
    position : absolute;
    width : calc(100% - 5rem);
    bottom : 60%;
    left : 2.5rem;
    margin-top : -3rem;
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(0,2rem,0);
  }
  .description {
    position : absolute;
    width : calc(100% - 5rem);
    top : 40%;
    left : 2.5rem;
    margin-top : 1rem;
    text-align : center;
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(0,2rem,0);
  }
}

.effect-bubba:hover{
  img {
    opacity: 0.2;
    transform: scale(1);
    filter: blur(.4rem);
  }
  .overlay::before,
  .overlay::after{
    opacity: 1;
    transform: scale(1);
  }
  .title,
  .description {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@media (max-width: $breakpoint-lg) {
  .real_item {
    width: calc(100% / 3);
    padding-top: calc(100% / 5);
  }
}

@media (max-width: $breakpoint-sm) {
  #realisations ul .real_item {
      width: calc(100% / 2);
      padding-top: calc(100% / 4);
  }
  .effect-bubba .title {
    top: 40%;
    bottom: auto;
  }
}

@media (max-width: $breakpoint-xs) {
  #realisations ul{
    .real_item {
      width: calc(100% / 1);
      padding-top: calc(100% / 2);
    }
    li .overlay .description {
      display: none;
    }
  }
  .ref-filtre ul{
    padding-left: 0;
    li {
      margin-left: 0.2rem;
      margin-bottom: 0.5rem;
      a {
        padding: 8px;
      }
    }
  }

  .effect-bubba{
    .title {
      top: 50%;
    }
    &:hover .title{
      transform: translate3d(0, 50%, 0);
    }
  }

}

