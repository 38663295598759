/* Positionnement */

@mixin absolute_container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin flex_center{
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex_between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex_around{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@mixin cover_img{
  display: block;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

/* Effets css */

@mixin box_shadow{
  box-shadow: 0 .6rem .8rem 0 #cfcfcf;
}

@mixin box_shadow_light{
  box-shadow: 0 .3rem .4rem 0 #cfcfcf;
}
