.bloc_img_produit{
  .image_produit_container {
    text-align: center;
    padding-bottom: 0;
    .image_produit_content{
      display: flex;
      background-size: contain;
    }
    img{
      object-fit: contain;
      width: 100%;
      height: 100%;
      padding: 1rem;
    }
  }
  .slider-single .slick-slide > div{
    margin: .5rem;
    height: 100%;
  }
  .slider-nav{
    margin-left: .2rem;
    .slick-prev{
      left: -3rem;
    }
    .slick-next{
      right: -3rem;
    }
    .slick-track {
      margin: 0 -.4rem;
      margin-top: 1rem;
      padding-bottom: 1rem;
      .slick-slide{
        padding-left: .2rem;
        margin: 0 .4rem;
        cursor: pointer;
      }
      img{
        object-fit: contain;
      }
    }
  }
}

.info-produit{
  .h1{
    font-size: 3.2rem;
    color: $color5;
    font-weight: 800;
    text-align: left;
    margin-bottom: 2rem;
  }
  p{
    margin-bottom: 0;
  }
  .reference{
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    color: $color5;
    margin-bottom: 2rem;
  }
  .stock{
    color: $color5;
    font-weight: 600;
    justify-content: flex-start;
    margin-top: 3rem;
  }
}

.achat_produit_c{
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: $color7;
  padding: 3rem;
  .bloc_prix{
    .prixbarre_container{
      display: flex;
      align-items: center;
      .prixbarre{
        font-size: 1.8rem;
        font-weight: 600;
      }
      .flag_product{
        height: 2.2rem;
        margin-left: 1rem;
        padding: .3rem 1.5rem;
      }
    }
    .prix{
      font-size: 3.2rem;
      font-weight: 400;
    }
    .prix_kg{
      font-size: 1.4rem;
      font-weight: 400;
      text-transform: none;
    }
  }
  .rayon_produit_c{
    display: flex;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    input{
      display: none;
    }
    label{
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: .1rem solid transparent;
      border-radius: .9rem;
      text-align: center;
      width: 8rem;
      height: 8rem;
      line-height: 1;
      cursor: pointer;
      transition: border-color .2s;
      &:hover{
        border-color: rgba(48, 48, 48, .5);
      }
      .icon{
        display: block;
        font-size: 3rem;
      }
      .libelle_rayon{
        height: 3.3rem;
      }
    }
    input:checked + label{
      border-color: $color5;
    }
    .rayon_produit + .rayon_produit{
      margin-left: 1.5rem;
    }
  }

  .format_produit_c{
    margin-top: 2.2rem;
    select{
      display: block;
      height: 5rem;
      min-width: 24rem;
      max-width: 100%;
      padding-left: .3rem;
      border: .1rem solid #000;
      font-weight: 700;
      font-size: 1.6rem;
      color: $color5;
    }
  }

  #panier{
    margin-top: 2.6rem;
    .qt_submit_block{
      display: flex;
      justify-content: space-between;
    }
    button[type="submit"]{
      padding: .5rem 2.5rem;
      letter-spacing: .15rem;
      font-size: 1.2rem;
      &:not(.dispo){
        border-color: $color2;
        background-color: $color2;
        cursor: not-allowed;
      }
      .icon{
        font-size: 2.8rem;
        margin-right: 1rem;
      }
      .textaddcart, .textnondispo, .textrupture {
        display: none;
      }
    }
    button[type="submit"].dispo {
      .textaddcart {
        display: inline-block;
      }
    }
    button[type="submit"].indispo {
      .textnondispo {
        display: inline-block;
      }
    }
    button[type="submit"].rupture {
      .textrupture {
        display: inline-block;
      }
    }
  }
}

#tabs_article{
  margin-top: 5rem;
  margin-bottom: 5rem;
  .nav-tabs{
    display: flex;
    justify-content: center;
    border-top: .2rem solid $color5;
    border-bottom: .2rem solid $color5;
    margin-bottom: 3rem;
    .nav-item{
      display: flex;
      justify-content: center;
      margin-bottom: 0;
      .nav-link{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: $color5;
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;
        border: none;
        height: 3.9rem;
        width: 28rem;
        text-align: center;
        border-radius: 0;
        &:after{
          position: absolute;
          left: 50%;
          bottom: -1.5rem;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 1rem .85rem 0 .85rem;
          border-color: transparent transparent transparent transparent;
          transition: border-color .3s ease-in;
        }
        .icon{
          font-size: 2rem;
          margin-right: 1rem;
        }
        &:focus{
          opacity: 1;
        }
        &.active{
          opacity: 1;
          color: #fff;
          background-color: $color1;
          &::after{
            border-color: #74000a transparent transparent transparent;
          }
        }
      }
    }
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active{
    border: none;
  }
  .tab-pane{
    color: $color2;
  }
  .tab-content{
    .document_dl{
      color: $color5;
      border: .2rem solid $color5;
      padding: 1rem 1.5rem;
      margin-top: 1.5rem;
      &:focus,
      &:hover{
        background-color: #fff;
        color: $color1;
      }
      .icon-download{
        font-size: 1.8rem;
        margin-right: 1.5rem;
      }
      .icon-pdf {
        margin-left: .5rem;
      }
    }
  }
}

.autre_produit{
  background-color: $color7;
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-bottom: 8rem;
  .h2{
    font-size: 3.2rem;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 5rem;
    .icon{
      margin-left: 1.5rem;
    }
  }
  .autre_produit_slider{
    width: calc(100% - 15rem);
    margin-right: auto;
    margin-left: auto;
    .card_product{
      width: calc(100% - 2rem);
      margin-right: 1rem;
      margin-left: 1rem;
    }
    .slick-prev{
      left: -7rem;
    }
    .slick-next{
      right: -7rem;
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .achat_produit_c{
    padding: 2rem;
  }
  .qt_submit_block{
    flex-wrap: wrap;
    button{
      margin-top: 2rem;
      width: 100%;
    }
  }
  .autre_produit .autre_produit_slider{
    width: calc(100% - 5rem);
  }
}

@media (max-width: $breakpoint-lg) {
  .autre_produit{
    padding-top: 3rem;
    padding-bottom: 1rem;
    margin-bottom: 4rem;
    .autre_produit_slider{
      width: calc(100% - 2rem);
    }
  }
}

@media (max-width: $breakpoint-md) {
  .page_article #chemin{
    margin-bottom: 0;
    min-height: 3rem;
  }
  .bloc_img_produit{
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    .image_produit_container{
      max-width: 30rem;
    }
    .slider-nav{
      max-width: 30rem;
      margin-left: auto;
      margin-right: auto;
      .slick-track .slick-slide{
        max-width: 7rem;
      }
    }
  }
  .info-produit_c{
    order: -1;
  }
  .achat_produit_c #panier .qt_submit_block{
    .qt_block{
      width: 100%;
    }
    button{
      max-width: 30rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
  #tabs_article .nav-tabs .nav-item{
    width: calc(100%/3);
    .nav-link{
      width: 100%;
      font-size: 1.4rem;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  #tabs_article .nav-tabs .nav-item .nav-link{
    line-height: 1.1;
  }
  .autre_produit .h2 {
    margin-bottom: 2.4rem;
  }
}

@media (max-width: $breakpoint-xs) {
  #tabs_article .nav-tabs .nav-item{
    width: 100%;
    .nav-link::after {
      content: none;
    }
  }
  .autre_produit .h2 {
    font-size: 2.2rem;
  }
}