#header {
  position: relative;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  padding: 1.5rem 1rem 1.2rem;
  box-shadow: 0 .2rem 2rem 0 rgba(0, 0, 0, .3);
}

#logo {
  width: 22rem;
}

/* Menu principal */

.right_col_header{
  width: 65%;
  text-align: right;
  .coordonnees_header{
    .icon{
      color: $color1;
      margin-right: .5rem;
    }
    .address_header{
      margin-right: 2rem;
    }
  }
  .menu_social_c{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: .5rem;
    .menu {
      width: 100%;
      margin-right: 1rem;
      ul {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
        .menu_nom {
          display: block;
          height: 100%;
          font-size: 1.6rem;
          color: $color3;
          cursor: default;
          font-weight: 500;
        }
        a.menu_nom {
          cursor: pointer;
        }
        .menu_nom:hover{
          color: $color1;
        }
      }
      ul.level1 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        > li{
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          &.dropdown{
            position: relative;
            .level2{
              position: absolute;
              top: 100%;
              left: 50%;
              width: 100%;
              transform-origin: top;
              transform: rotateX(-90deg) translateX(-50%);
              background-color: #fff;
              box-shadow: 0 .6rem .8rem 0 #cfcfcf;
              z-index: 1001;
              transition: transform .3s linear;
              min-width: 30rem;
              padding: 1.2rem 1rem;
              a{
                text-align: left;
                font-size: 1.8rem;
                padding: .8rem 0;
              }
            }
            &:hover .level2 {
              transform: rotateX(0deg) translateX(-50%);
            }
          }
        }
      }
    }
    .sociaux_header{
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      height: 4.4rem;
      a{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        .icon{
          font-size: 2rem;
          line-height: 0;
        }
      }
    }
  }

}


/* Sticky */
#header.sticky{
  height: 12.1rem;
  z-index: 999;
  .header_top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    border-bottom: none;
    box-shadow: 0 .2rem 2rem 0 rgba(0, 0, 0, .3);
    background-color: #fff;
    #logo{
      width: 18rem;
    }
    .coordonnees_header{
      margin-top: 1rem;
    }
    .menu_social_c{
      margin-top: 0;
    }
  }
}

/* Header page */
.header_page_c .header_page{
  @include flex_center;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &::after{
    content: '';
    display: block;
    @include absolute_container;
    background-color: rgba(0, 0, 0, .3);
  }
  .header_page_content{
    max-width: 100rem;
    color: #fff;
    text-align: center;
    .h1{
      position: relative;
      z-index: 1;
      font-size: 3.2rem;
      text-transform: uppercase;
      color: #fff;
      line-height: 1.4;
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .right_col_header {
    width: 80%;
  }
}

@media (max-width: $breakpoint-lg) {
  #logo{
    width: 18rem;
  }
  .right_col_header .menu_social_c #primary_nav_wrap{
    width: calc(100% - 10rem);
  }
}

@media (max-width: $breakpoint-md) {
  #header{
    padding: 1rem 0;
  }
  .right_col_header{
    width: 0;
    > *{
      display: none;
    }
  }
  .menu{
    display: none;
  }

}

@media (max-width: $breakpoint-sm) {
  #header{
    padding: 0;
    #logo{
      width: 11rem;
    }
    .coordonnees_header{
      display: none;
    }
    &.sticky{
      height: 5rem;
      .header_top #logo{
        width: 10rem;
      }
      .right_col_header .menu_social_c .sociaux_header{
        display: flex;
        align-items: center;
      }
    }
  }
}
