/* Home slider */
.homeslider {
  position: relative;
  .slick-slide {
    //height: 60rem;
    img{
      //height: 60rem;
      width: 100%;
      object-fit: cover;
    }
    .islide_content{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      .container{
        @include flex_center;
      }
      .btn_contact{
        position: absolute;
        top: 2rem;
        right: 2rem;
        .button{
          font-size: 2.3rem;
          padding: .7rem 4.5rem;
        }
      }
      .islide{
        padding: 0 4rem;
        margin-top: 8rem;
        .h1{
          font-size: 9rem;
          color: #fff;
          text-transform: initial;
          line-height: .9;
          margin-bottom: 2rem;
        }
        .h3{
          font-size: 3.2rem;
          color: #fff;
          text-align: center;
          text-transform: initial;
          letter-spacing: 1px;
        }
        .button_homeslider_c{
          text-align: center;
          margin-top: 4rem;
          a{
            margin-right: .5rem;
            margin-left: .5rem;
            .button{
              padding: .8rem 5.8rem;
            }
          }
        }
      }
    }
  }
  .button{

    &:hover{
      background-color: #fff;
    }
  }
}

/* Blocs rassurances home */
.green_block{
  margin-top: -4rem;
  width: calc(100% - 53rem);
  padding: 3rem 3rem 4.5rem 8rem;
  h3,
  .h3 {
    color: #fff;
    max-width: 34rem;
    text-transform: none;
  }
  background-color: $color2;
  .icon{
    color: $color1;
  }
  p{
    font-size: 1.6rem;
    color: #fff;
    max-width: 44rem;
  }
}
.rassurance_home{
  display: flex;
}
.bloc_rassu_home_c{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 10rem;
  row-gap: 1rem;
  .bloc_rassu_home{
    display: flex;
    align-items: center;
    .icon{
      font-size: 7rem;
      margin-right: 3.5rem;
      line-height: 0;
    }
    &.block_rassu_link{
      display: block;
    }
  }
  .button{
    margin-top: 2rem;
  }
}
.apropos_home{
  max-width: 70rem;
  padding-left: 5.5rem;
  padding-right: 7.5rem;
  padding-top: 3rem;
  .made_fr{
    display: flex;
    align-items: center;
    margin-top: 5rem;
    font-size: 2.8rem;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.2;
    .icon{
      margin-right: 2rem;
    }
  }
}

.icon-map-france{
  display: inline-block;
  content: url("../assets/images/map-france.svg");
  min-width: 14rem;
}

/* Slider competences */
.slider_competences_c{
  margin-top: 8rem;
  margin-bottom: 6rem;
  .h4{
    text-align: center;
    text-transform: initial;
    font-size: 3.2rem;
    color: #8D8E91;
    font-weight: 300;
  }
  .h2{
    text-align: center;
    text-transform: uppercase;
    font-size: 4.2rem;
    font-weight: 700;
    margin-bottom: 4rem;
  }
  .slick-track{
    display: flex;
    align-items: center;
    img{
      padding-right: 1rem;
      padding-left: 1rem;
      margin-right: auto;
      margin-left: auto;
      transition: opacity .3s;
      max-width: 20rem;
      &:hover{
        opacity: .8;
      }
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .green_block{
    padding-left: 2rem;
    padding-right: 2rem;
    .bloc_rassu_home_c{
      column-gap: 5rem;
      row-gap: 2rem;
      .bloc_rassu_home{
        &.block_rassu_link{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          h3{
            width: 100%;
          }
        }
        .icon{
          font-size: 5rem;
          margin-right: 3rem;
        }
      }
    }
  }
  .apropos_home{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .icon-map-france{
    width: 10rem;
  }
}

@media (max-width: $breakpoint-lg) {
  .homeslider .slick-slide{
    //height: 40rem;
    //img{
    //  height: 40rem;
    //}
    .islide_content .islide {
      margin-top: 8rem;
      .h1{
        font-size: 5rem;
      }
      .button_homeslider_c{
        margin-top: 2rem;
      }
    }
  }
  .green_block{
    width: calc(100% - 40rem);
  }
  .apropos_home{
    max-width: 40rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .home_actu{
    margin-top: 2rem;
  }
  .slider_competences_c{
    margin-top: 5rem;
    margin-bottom: 3rem;
  }
}

@media (max-width: $breakpoint-md) {
  .homeslider{
    .slick-slide .islide_content .islide{
      h3{
        font-size: 2.6rem;
      }
      .button_homeslider_c a .button{
        font-size: 2.6rem;
        padding: 0.4rem 4.5rem;
      }
    }
  }
  .green_block{
    width: calc(100% - 35rem);
    p{
      font-size: 1.3rem;
    }
    .bloc_rassu_home_c{
      column-gap: 4rem;
      row-gap: 2rem;
      .bloc_rassu_home{
        flex-wrap: wrap;
        text-align: center;
        .icon{
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 1rem;
        }
      }
      .bloc_rassu_home.block_rassu_link{
        h3{
          margin-top: 6rem;
        }
        > div{
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  .apropos_home{
    max-width: 35rem;
    .made_fr{
      font-size: 2.2rem;
    }
  }
  .actu_list_home{
    .h4{
      font-size: 2.6rem;
    }
    .home_actu_desc{
      font-size: 1.4rem;
      line-height: 1.2;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .homeslider .slick-slide{
    .islide_content{
      .islide{
        padding: 0;
        .h1{
          font-size: 4rem;
        }
        .h3{
          display: none;
        }
        .button_homeslider_c a .button{
          padding: .2rem 1.5rem;
          font-size: 2.2rem;
        }
      }
      .btn_contact .button{
        font-size: 2.3rem;
        padding: .2rem 1.5rem;
      }
    }
  }
  .rassurance_home{
    flex-wrap: wrap;
    .green_block,
    .apropos_home {
      width: 100%;
      max-width: none;
    }
    .green_block .bloc_rassu_home_c{
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }
  }
  .slider_competences_c .slick-track img {
    max-width: 14rem;
  }
  .apropos_home .made_fr{
    margin-top: 3rem;
    justify-content: center;
  }
}
@media (max-width: $breakpoint-xs) {
  .page_home h2{
    font-size: 3rem!important;
  }
  .homeslider .slick-slide{
    //height: 20rem;
    .islide_content{
      .islide{
        margin-top: 0;
        .h1{
          font-size: 3rem;
        }
      }
      .btn_contact{
        display: none;
      }
    }
  }
  .rassurance_home .green_block{
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 2.5rem;
    .bloc_rassu_home_c{
      column-gap: 2rem;
      row-gap: 2rem;
      grid-template-columns: 1fr 1fr;
      .bloc_rassu_home{
        justify-content: center;
        .icon{
          width: 100%;
          margin-bottom: .3rem;
        }
        p{
          display: none;
        }
        &.block_rassu_link h3{
          margin-top: 2rem;
          margin-bottom: 1.5rem;
        }
        .button{
          margin-top: 0;
        }
      }
    }
  }
  .rassurance_home{
    .green_block{
      margin-bottom: 2rem;
    }
    .apropos_home p{
      font-size: 1.4rem;
    }
  }
  .home_actu .actu_content{
    margin-right: 0;
    margin-left: 0;
    .actu_list_home li{
      .row a{
        display: flex;
        justify-content: center;
      }
      .date_btn{
        padding: 0.4rem 1.2rem;
      }
    }
  }
  .slider_competences_c{
    margin-top: 3rem;
    .h4{
      font-size: 2.6rem;
    }
    h2{
      font-size: 3rem;
    }
  }
  .icon-map-france {
    width: 6rem;
  }
}
