.textcenter{
  text-align: center;
}

.textright{
  text-align: right;
}

.collapse_box {
  margin-bottom: 4rem;
  .collapse_item {
    margin: 1rem auto 1rem 2.5rem
  }
}

.min-height{
  min-height: 60rem;
  @include flex_center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mw-1000{
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
}

.bold{
  font-weight: 700;
}

.small{
  font-size: 80%;
}

.abs_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.grey_block{
  background-color: $color7;
  padding: 2rem;
}

/* Toast */
.alert-container .alert{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: #fff;
  &.alert-danger{
    background-color: #f35958;
  }
  &.alert-success{
    color: #155724;
    background-color: #d4edda;
  }
}

/* Bloc quantité */
.qt_block{
  display: flex;
  align-items: center;
  text-align: center;
  .qt_manage,
  .inpQtyCart{
    display: inline-block;
    height: 4.8rem;
    width: 2rem;
    border: .1rem solid $color7;
  }
  input.inpQtyCart {
    position: relative;
    width: 6rem;
    text-align: center;
    font-size: 2.2rem;
    padding-left: 0;
    padding-bottom: .4rem;
  }
  .qt_manage{
    @include flex_center;
    width: 3rem;
    color: $color1;
    background-color: #fff;
    font-size: 3.5rem;
    font-weight: 400;
    cursor: pointer;
    transition: color .3s;
    &:hover{
      color: $color2;
    }
    &.less_qt{
      margin-right: -.1rem;
      span{
        font-size: 4rem;
        margin-top: -.8rem;
      }
    }
    &.more_qt{
      margin-left: -.1rem;
    }
  }
}

/* Pastille stock */
.stock{
  @include flex_center;
  font-size: 1.4rem;
  font-weight: 700;
  color: $color5;
  > span{
    line-height: 0;
  }
  .icon-stock{
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    margin-left: 1rem;
    background-color: #7EBC00;
    border-radius: 50%;
    &.icon-outstock{
      background-color: #B20C0C;
    }
  }
}

/* Prix barré */
.prixbarre{
  position: relative;
  &::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: .1rem;
    background-color: #333;
  }
}

// Permet de garder le ratio.
// Pour changer le ratio (carré par défaut), ajouter sur l'élément container avec la classe ratio un style="--ratio: 16/9"
.ratio {
  position: relative;
}

.ratio::before {
  content: '';
  display: block;
  padding-bottom: calc(100% / (var(--ratio, calc(1))));
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
}

/* chemin img */
/* # {$base-img-url} */
$base-img-url: "../img/theme";

@media (min-width: $breakpoint-xl) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: $breakpoint-xl;
  }
}

@media (max-width: $breakpoint-sm) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .desktop {
    display: none;
  }
}

/* AOS disable en-dessous de 768px */
@media screen and (max-width: 768px) {
  [data-aos] {
    pointer-events: auto !important;
  }

  html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
    opacity: 1 !important;
  }

  html:not(.no-js) [data-aos=fade-up] {
    transform: none !important;
  }
}

