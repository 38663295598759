#map {
  .leaflet-popup,
  .leaflet-popup .leaflet-popup-content-wrapper,
  .leaflet-popup .leaflet-popup-content{
    width: 20rem!important;
    height: auto;
  }

  .leaflet-popup-content{
    padding: 1rem;
    .logo_popup{
      max-width: 15rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1rem;
    }
  }
}