#pagination{
  margin-top: 4rem;
  li{
    margin-right: .5rem;
    margin-left: .5rem;
    list-style-type: none;
    &:not(.navig){
      a{
        font-weight: bold;
        display: inline-block;
        padding: .7rem 1.7rem;
        font-size: 2rem;
        text-align: center;
        color: #000;
        border-radius: .5rem;
        background: #000;
      }
      a:hover,
      &.current a {
        color: #fff;
        background: $color1;
      }
    }
    &.navig{
      margin-right: .8rem;
      margin-left: .8rem;
      a {
        font-size: 2.5rem;
        color: #000;
        &:hover{
          color: $color1;
        }
        .icon{
          @include flex_center;
        }
      }
    }
  }
}
