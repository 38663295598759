#body_cms .content .button,
.button {
  display : inline-block;
  color: #fff;
  background-color: $color1;
  border: .1rem solid $color1;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1.2rem 3.5rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: all .3s ease-in;
  &:hover,
  &:focus {
    color: $color1;
    background-color: #fff;
  }
  &.button-small {
    font-size: 1rem;
    padding: .6rem 1.2rem;
  }
  &.button-big {
    font-size: 1.8rem;
    padding: 2.4rem 5rem;
  }
  &.button-outline{
    color: $color1;
    background-color: #fff;
    &:hover,
    &:focus {
      color: #fff;
      background-color: $color1;
    }
  }
  &:not([href]){
    cursor: default;
  }
}

.btn_container_center{
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.button.button-border-left{
  color: #fff;
  background-color: $color3;
  font-size: 2.4rem;
  border: none;
  border-left: 1rem solid $color1;
  text-transform: initial;
  padding: 2rem 4rem;
}

.button.button-border-left:hover{
  border-left-width: 2rem;
}
