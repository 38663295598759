.products_list_card{
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-right: -1rem;
  margin-left: -1rem;
}

.card_product{
  position: relative;
  width: calc(25% - 2rem);
  overflow: hidden;
  margin-bottom: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  background-color: #fff;
  box-shadow: 0 6px 5px 0 #dfdfdf;
  display: flex;
  flex-direction: column;

  .img_block{
    position: relative;
    order: 1;
    min-height: 280px;
    a{
      img{
        height: 100%;
        width: 100%;
        padding: 1rem;
        object-fit: contain;
      }
    }
    .flag_product{
      position: absolute;
      top: 1rem;
      left: 1rem;
    }
  }
  .infos_product{
    order: 2;
    height: 20rem;
    padding: 1.8rem;
    color: $color1;
    text-align: center;
    background-color: #fff;
    transition: transform .3s;
    .product_name{
      height: 8rem;
      overflow: hidden;
      a{
        font-size: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $color1;
      }
    }
    .price{
      font-size: 3rem;
      margin-bottom: .8rem;
      color: $color5;
      .old_price{
        display: inline-block;
        color: $color2;
        font-size: 1.7rem;
        margin-left: 1.2rem;
        &::after{
          content: "";
          display: block;
          height: .1rem;
          margin-top: -1.3rem;
          background-color: $color2;
        }
      }
      .price_tax{
        font-size: 1.2rem;
      }
    }
    .add_cart{
      margin-top: 3.5rem;
    }
  }
  &:hover .infos_product{
    transform: translateY(-8.4rem);
  }
}

.flag_product{
  padding: 0.4rem 1.4rem;
  font-size: 1.1rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  background-color: $color1;
}


.label_product{
  position: absolute;
  top: .7rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  img{
    width: 3.8rem;
    + img{
      margin-top: .6rem;
    }
  }
}

.add_cart .button{
  color: #fff;
  border-color: $color1;
  padding: 1rem 0;
  text-align: center;
  width: 100%;
  .icon{
    color: #fff;
    font-size: 2.5rem;
    margin-right: 3rem;
    transition: color .3s ease-in;
  }
  &:focus,
  &:hover{
    opacity: 1;
    color: $color1;
    background-color: #fff;
    .icon{
      color: $color1;
    }
  }
}

.old-price{
  position: relative;
  &::after{
    content: "";
    position: absolute;
    top: 50%;
    right: -.2rem;
    left:-.2rem;
    border-top: 1px solid $color1;
  }
}

/* Product list line */
.products_list_line .product_line{
  @include flex_between;
  .product_img_line{
    width: 10rem;
    height: 10rem;
  }
  .product_name{
    width: calc(100% - 50rem);
    padding-left: .8rem;
    line-height: 1.2;
    a{
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 700;
      color: $color1;
      margin-bottom: .6rem;
      &:hover{
        color: $color2;
      }
    }
    .ref_product_line{
      font-size: 1.4rem;
      color: $color2;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  .add_cart .button{
    width: 20rem;
  }
}

.grey-sep-full {
  height: .1rem;
  width: 100%;
  background-color: #dfdfdf;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: $breakpoint-xl) {
  .card_product{
    width: calc(25% - 1rem);
    margin-right: .5rem;
    margin-left: .5rem;
  }
}

@media (max-width: $breakpoint-lg) {
  .add_cart .button .icon{
    margin-right: 1rem;
  }
  .card_product .infos_product{
    padding: 1.8rem;
  }
}

@media (max-width: $breakpoint-md) {
  .card_product .infos_product {
    padding: 1.8rem 0.5rem;
  }
}