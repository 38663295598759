#chemin {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  min-height: 4rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  a{
    color: $color5;
    margin-left: 1rem;
    margin-right: 1rem;
    &:hover{
      color: $color1;
    }
  }
}