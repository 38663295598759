.bloc_rassu_c{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10rem;
  .bloc_rassu{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(25% - 10rem);
    height: 0;
    padding-top: 18.2%;
    text-align: center;
    background-image: url("../img/theme/rond-rassurance.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    .bloc_rassu_abs{
      @include absolute_container;
      .bloc_rassu_content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        padding-left: 16%;
        padding-right: 16%;
        .h5{
          font-size: 1.8rem;
          font-weight: 700;
          color: $color1;
          margin-bottom: 0;
          &::after{
            content: '';
            display: block;
            width: 5rem;
            height: .2rem;
            background-color: $color1;
            margin: 1rem auto .5rem;
          }
        }
        p{
          font-size: 1.5rem;
          line-height: 1.2;
          color: $color5;
        }
      }
    }
  }
  .icon{
    font-size: 6rem;
    color: $color9;
  }
}

@media (max-width: $breakpoint-xl) {
  .bloc_rassu_c .bloc_rassu{
    width: 24%;
  }
}

@media (max-width: $breakpoint-lg) {
  .bloc_rassu_c{
    margin-top: 4rem;
    .bloc_rassu{
      padding-top: 24.2rem;
      .bloc_rassu_abs .bloc_rassu_content {
        .h5{
          font-size: 1.7rem;
          padding-left: 10%;
          padding-right: 10%;
        }
        p{
          font-size: 1.4rem;
        }
      }
    }
    .icon{
      display: none;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .bloc_rassu_c{
    flex-wrap: wrap;
    margin-top: 6rem;
    .bloc_rassu{
      padding-top: 50%;
      width: calc(50% - 2rem);
      max-width: 30rem;
      margin-top: -5rem;
      margin-bottom: 3rem;
      .bloc_rassu_abs .bloc_rassu_content .h5{
        font-size: 2.2rem;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .bloc_rassu_c{
    margin-top: 2rem;
    .bloc_rassu{
      margin-top: -1rem;
      .bloc_rassu_abs .bloc_rassu_content{
        .h5{
          font-size: 2rem;
        }
        p{
          display: none;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .bloc_rassu_c .bloc_rassu{
    width: calc(50% - .5rem);
    .bloc_rassu_abs .bloc_rassu_content .h5{
      font-size: 1.4rem;
    }
  }
}

