/* Affichage date et tags */
.tag_label,
.tag_actu {
  display: inline-block;
  font-size: 1.2rem;
  padding: .2rem 0.5rem;
  color: #fff;
  background-color: $color1;
  + .tag_label{
    margin-left: .2rem;
  }
}


.date_news{
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: $color1;
  padding: .2rem .6rem;
  z-index: 1;
}

/*** Liste des news ***/

.liste_articles article{
  margin-bottom: 2rem;
  .news_card{
    height: 100%;
    background-color: #eee;
    .link-news-img{
      display: block;
      position: relative;
      .list_tags{
        position: absolute;
        left: .4rem;
        bottom: .4rem;
        .tag_label{
          font-size: 1.2rem;
          padding: .2rem .6rem;
          + .tag_label{
            margin-left: 0;
          }
        }
      }
    }
    .news_card_content{
      height: 15rem;
      padding: .5rem 1rem 1rem;
      .desc{
        color: $color1;
      }
    }
    .button{
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

/*** News active ***/
.news_content{
  margin-top: 3rem;
  .img_news_c{
    position: relative;
    .date_news{
      top: 1rem;
      right: 1rem;
    }
  }
  .news_txt{
    margin-top: 2rem;
  }
}
