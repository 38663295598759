@mixin title_global{
  word-wrap: normal;
  hyphens: none;
  line-height: 1;
}

@mixin h1{
  position: relative;
  color: #000;
  font-size: 3.2rem;
  font-weight: 700;
  margin: 1rem 0 .5rem;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: $breakpoint-md) {
    font-size: 2.5rem
  }
}

@mixin h2{
  font-size: 2.8rem;
  color: $color5;
  margin: .6rem 0;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  @media (max-width: $breakpoint-md) {
    font-size: 2.2rem
  }
}

@mixin h3{
  font-size: 2.5rem;
  color: $color5;
  margin: .6rem 0;
  font-weight: 700;
  line-height: 1;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
}

@mixin h4 {
  font-size: 2rem;
  color: $color5;
  margin: .6rem 0;
  font-weight : 400;
  line-height: 1;
  text-transform: uppercase;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
}

@mixin h5 {
  font-size: 2rem;
  color: $color5;
  margin: .6rem 0;
  font-weight : 400;
  line-height: 1;
  text-transform: uppercase;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
}

@mixin h6 {
  font-size: 2rem;
  color: $color5;
  margin: .6rem 0;
  font-weight : 400;
  line-height: 1;
  text-transform: uppercase;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
}