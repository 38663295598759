/* NEW */
.espace_client .button + .button{
    margin-left: 1.5rem;
}

.card_home_ec{
  margin-top: 1rem;
  margin-bottom: 1rem;
  .h2{
    @include flex_center;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 700;
    background: $color1;
    height: 3.5rem;
    transition: opacity .3s;
    &:focus,
    &:hover{
      opacity: .8;
    }
  }
  .card_home_ec_content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .icon{
      font-size: 3.5em;
      color: #303432;
      margin-right: 1.5rem;
    }
  }
}

.ec_card{
  background-color: $color6;
  padding: 3rem;
}

/* Espace personnel, tableaux */
.table_espace_client{
  width: 100%;
  margin-top: 3rem;
  thead{
    background-color: #efefef;
  }
  tr{
    text-align: center;
    height: 5.5rem;
    border-bottom: .2rem solid $color7;
  }
  tr:nth-child(2n){
    background-color: #fcfcfc;
  }
  th, td{
    text-align: center;
    padding: 1rem .5rem;
  }
  .icon-eye{
    font-size: 2.8rem;
    line-height: 1;
  }
  .detail_nom{
    text-align: left;
    padding-left: 1.5rem;
    text-transform: uppercase;
    a{
      color: $color1;
      font-size: 1.6rem;
      font-weight: 700;
    }
    .ref{
      font-size: 1.4rem;
      font-weight: 600;
      color: #777;
    }
  }
  .total_detail{
    font-weight: 700;
  }
}

/* Page détail de commande */
.totalshopline{
  text-align: right;
  margin-top: 1.5rem;
  font-weight: 700;
  font-size: 2.2rem;
  span + span{
    margin-left: 1.5rem;
  }
}

.list_achat_card{
  margin-top: 1.5rem;
  > div{
    margin-top: 1.5rem;
  }
}
.achat_card{
  height: 100%;
  padding: 1.5rem;
  background-color: $color7;
  .h3{
    text-align: center;
  }
  .button-red{
    padding: 1rem 2.5rem;
  }
  .achat_paiement_cta{
    margin-top: 1.5rem;
    text-align: center;
  }
}

/* Page paniers sauvegardés */
.paniers_rec{
  .liste_paniers_rec{
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    max-width: 50rem;
    margin-right: auto;
    margin-left: auto;
    li{
      @include flex_between;
      height: 6rem;
      padding-left: 2rem;
      padding-right: 2rem;
      margin-bottom: 1rem;
      background-color: $color7;
      .liste_paniers{
        @include flex_center;
        a.icon-link{
          color: $color5;
          font-size: 2.6rem;
          margin-left: 1.5rem;
          &:hover{
            color: $color1;
          }
          + a {
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
}

/* Module questions */
.list_questions{
  list-style-type: none;
}

.reponse{
  background-color: #fff;
}

#espaceClientInt{
  ul {
    list-style-type: none
  }
  h2 {
    margin: 2rem 0 1rem 0;
  }
}

.erreur {
  color: #dd2a00;
  font-weight: 400;
}

@media (max-width: $breakpoint-xs) {
  button.submit,
  input[type=button],
  input[type=submit] {
    min-width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .formline,
  .submitline {
    line-height: 3rem;
  }

}

