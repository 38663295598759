@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('../fonts/OpenSans-Light.woff2') format('woff2'),
  url('../fonts/OpenSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('../fonts/OpenSans-Regular.woff2') format('woff2'),
  url('../fonts/OpenSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Medium'), local('OpenSans-Medium'),
  url('../fonts/OpenSans-Medium.woff2') format('woff2'),
  url('../fonts/OpenSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../fonts/OpenSans-Bold.woff2') format('woff2'),
  url('../fonts/OpenSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}