.header_page_c{
  min-height: 7.5rem;
  .header_page{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.page_catalogue{
  #chemin{
    margin-bottom: 0;
  }
  .img_category_container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    img{
      object-fit: contain;
    }
  }
  .icon-sep_wave{
    font-size: 5rem;
    margin-left: auto;
    margin-right: auto;
    line-height: 0;
  }
  .h1{
    font-size: 2.9rem;
    margin-bottom: 2rem;
  }
  .h2{
    text-align: center;
    font-size: 2.8rem;
  }
  .desc_category_container{
    max-width: 80rem;
    text-align: center;
    color: $color2;
    line-height: 1.8;
    margin-left: auto;
    margin-right: auto;
  }
}

.listing_catalogue_c{
  padding-top: 4rem;
  padding-bottom: 7rem;
  margin-top: 3rem;
  background-color: $color7;
}

/* Colonne de gauche */
.filtres_col{
  .h2{
    color: $color3;
    font-size: 2.2rem;
    font-weight: 800;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 1.5rem;
    margin-left: -1rem;
  }
  label{
    cursor: pointer;
  }
  hr{
    display: block;
    background-color: $color3;
    height: .1rem;
    width: calc(100% + 1rem);
    margin-left: -1rem;
    margin-top: 0;
    margin-bottom: 0;
    border: none;
    &.light{
      background-color: $color4;
      margin-bottom: .5rem;
    }
  }
  ul{
    list-style-type: none;
    padding-left: 1rem;
  }
  .filter_selection{
    input{
      margin-right: .5rem;
    }
    label{
      color: $color2;
    }
    input:checked + label{
      color: $color1;
    }
  }
  .filter_libelle_c{
    @include flex_between;
    padding-top: 1rem;
    padding-bottom: .5rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: $color1;
    font-weight: 600;
    cursor: pointer;
    transition: color .3s;
    .icon{
      font-size: 2.5rem;
      margin-right: .5rem;
    }
    &:hover{
      color: $color2;
    }
  }
  .collapse_block.see_more{
    display: block;
    cursor: pointer;
    transition: transform .3s;
    > span{
      display: block;
      width: 1.3rem;
      height: .1rem;
      background-color: $color6;
    }
  }
  .filter_form_c{
    height: auto;
    overflow: hidden;
    .filter_form{
      input{
        margin-left: 1.5rem;
      }
      label{
        font-size: 1.4rem;
        padding-left: .3rem;
      }
    }
  }
}

.filter_block.closed{
  .see_more{
    transform: rotate(90deg);
  }
  .filter_form_c{
    height: 0;
  }
}

/* Colonne de droite */
.products_list_col{
  .header_products_list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 1.1rem 1rem 1.1rem 3.3rem;
    margin-bottom: 4rem;
    font-size: 1.4rem;
    .nb_products{
      font-weight: 600;
    }
    .orga_products{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 55rem;
      label{
        font-weight: 600;
        margin-bottom: 0;
      }
      .select_c{
        position: relative;
        cursor: pointer;
        padding-right: 2rem;
        select{
          color: $color5;
          border: none;
          appearance: none;
          outline: 0;
          cursor: pointer;
          padding: 0 .5rem;
        }
        &::after{
          content: "\EA12";
          display: block;
          position: absolute;
          top: .3rem;
          right: 1rem;
          font-family: kfont;
          font-size: 1rem;
        }
      }
      .icon{
        font-size: 2.8rem;
        line-height: 1;
        color: $color4;
        &.current{
          color: $color5;
        }
        + .icon{
          margin-left: .8rem;
        }
      }
    }
  }
  &.grid-list{
    .products_list_line{
      display: none;
    }
  }
  &.table-list{
    .products_list_line{
      display: block;
    }
    .products_list_card{
      display: none;
    }
  }
}

/* Pagination */
.pagination_catalogue{
  margin-top: 2rem;
  text-align: center;
  .count{
    font-size: 1.8rem;
    color: #707070;
  }
  .progress_bar_catalogue{
    position: relative;
    height: .4rem;
    width: 23rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    border: .1rem solid $color2;
    .progress_status{
      position: absolute;
      top: -.1rem;
      left: -.1rem;
      background-color: $color5;
      height: .4rem;
    }
  }
  .button{
    padding: 1.6rem 7.7rem;
    margin-top: 3.5rem;
  }
}

@media (max-width: $breakpoint-xl) {
  .filtres_col .filter_form_c .filter_form input{
    margin-left: .5rem;
  }
  .products_list_card{
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
}

@media (max-width: $breakpoint-lg) {
  .card_product{
    width: calc(100% / 3 - 1rem);
  }
}

@media (max-width: $breakpoint-md) {
  .card_product{
    width: calc(100% / 2 - 1rem);
  }
  .products_list_col .header_products_list{
    flex-wrap: wrap;
    .nb_products{
      order: 2;
    }
  }
  .products_list_line .product_line{
    flex-wrap: wrap;
    .product_name{
      width: calc(100% - 12rem);
    }
    .prix{
      order: 4;
    }
    .add_cart{
      order: 5;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .img_category_container_col {
    order: 2;
    margin-top: 2.5rem;
  }
  .products_list_col .header_products_list{
    padding-left: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1.2rem;
  }
}

@media (max-width: $breakpoint-xs) {
  .products_list_col .header_products_list{
    .nb_products{
      position: absolute;
      right: 3rem;
    }
    .orga_products{
      flex-direction: column;
      align-items: flex-start;
      > div{
        margin-bottom: 1rem;
      }
    }
  }
  .card_product{
    width: 100%;
  }
  .products_list_line .product_line{
    .stock{
      margin-top: .6rem;
      margin-bottom: .6rem;
    }
    .add_cart{
      width: 100%;
    }
  }
}